var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"vnAEd1qFbTC4ZedytMRPc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { SENTRY_TRACKING_ID } from "./src/constants/env";

Sentry.init({
  dsn: SENTRY_TRACKING_ID,
  environment: process.env.SENTRY_ENVIRONMENT,

  integrations: [
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        "https://binder.papercup.com",
        "https://binder-dev.papercup.com",
        "https://binder.papercup.com",
        "https://binder-gamma.papercup.com",
        "https://binder-delta.papercup.com",
        "https://binder-beta.papercup.com",
        "https://binder-petri.papercup.com"
      ],
      networkCaptureBodies: true
    })
  ],
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // this enables perofrmance monitoring
  tracesSampleRate: 0.15,

  ignoreErrors: [
    // These events occur very frequently, and there is not much we can do about it
    "Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type",
    "ResizeObserver loop limit exceeded",
    "AbortError: The play() request was interrupted by a call to pause().",
    "NotSupportedError: The element has no supported sources."
  ]
});
